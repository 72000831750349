.easy-edit-component-wrapper {
  width: 100%;
}
.productDetail-container {
  background-color: white !important;
  padding: 0px 0px;
  flex: 1 1 auto;
  .ant-typography {
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .title {
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-right: 5px;
    margin-bottom: 50px;
  }
  .productPrice {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
  .product-title {
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 5px;
  }
  .section-title {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 5px;
    margin-bottom: 20px;
    display: inline-block;
  }
  section {
    // display: flex;
    // align-items: baseline;
    .section-title {
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }
    .editLink {
      margin: 0 4px;
    }
    .bold-text {
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0em;
      text-align: left;
    }
    .new-thumbnail-preview {
      width: 100px;
      height: 100px;
    }
  }
}

.editLink {
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  color: #0091fb;
}
