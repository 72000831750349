.bitreel-fullscreen-spinner {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.696);
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    text-align: center;
    h3 {
      color: white;
      margin-top: 20px;
    }
  }
}

.spinner {
  /* Spinner size and color */
  width: 100px;
  height: 100px;
  border-top-color: #34d2e4;
  border-left-color: #0091fb;

  /* Additional spinner styles */
  animation: spinner 1000ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Optional — create your own variations! */
.spinner-large {
  width: 5rem;
  height: 5rem;
  border-width: 6px;
}

.spinner-slow {
  animation: spinner 1s linear infinite;
}

.spinner-blue {
  border-top-color: #09d;
  border-left-color: #09d;
}
