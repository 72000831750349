.audio-uploader {
  display: flex;
  align-items: center;
  .ant-upload-wrapper {
    width: auto;
    &.inserted-file {
      display: none;
    }
    .ant-upload-list-item-actions {
      .ant-upload-list-item-action {
        display: none;
      }
    }
  }
  .file-properties-wrapper {
    display: flex;
    .play-button,
    .pause-button {
      font-size: 36px;
      margin-right: 10px;
      cursor: pointer;
    }
    .file-properties {
      .file-name {
        font-style: italic;
        font-size: 12px;
      }
      .file-actions {
        .ant-btn {
          font-size: 12px;
          padding: 0;
          margin: 0;
        }
        .divider {
          margin: 0 5px;
        }
      }
    }
  }
}
