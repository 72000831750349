.screen-container {
  &.products-list {
    background-color: #eeeeee;
    width: 100%;
    height: 100%;
    .orders-menu {
      position: absolute;
      top: 80px;
      left: 20px;
      z-index: 1;
      .product-actions-group {
        position: fixed;
        bottom: 20px;
        width: 223px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }
    .table-container {
      padding: 10% 5%;
    }
    .bitreel-filter-section {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .bitreel-table-filter {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        input {
          width: 200px;
        }
      }
      margin-bottom: 20px;
    }
  }
}
