.showroom-layout-wrapper {
  .layout-showroom {
    .siderMenu--showrooms {
      .ant-layout-sider-children {
        background-color: white;
        .cardButtonsMenu {
          .ant-card-body {
            padding-bottom: 0;
          }
        }
        .ant-menu {
          &-item {
            .master-icon {
              margin-left: -24px;
            }
          }
          &-title-content {
            .menu-item-label {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .ant-btn {
                display: none;
              }
            }
            &:hover {
              .ant-btn {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
