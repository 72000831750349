.context-menu-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .context-menu-layover {
    display: none;
    &.active {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      .context-menu-container {
        display: block;
      }
    }
    .context-menu-container {
      position: absolute;
      display: none;
    }
  }
}
