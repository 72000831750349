.layouts-explorer-context-menu {
  .ant-dropdown-menu-item {
    span {
      i {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}

.add-materials-modal {
  min-width: 890px;
  .ant-modal {
    &-header {
      margin-bottom: 20px;
    }
    &-title {
      text-align: center;
    }
    &-body {
      height: 600px;
      overflow-y: scroll;
      .bitreel-fullarea-spinner {
        width: 100%;
      }
    }
  }
  .materials-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 15px;

    .material-category-card {
      width: 100%;
      border: 1px solid #e8e8e8;
      padding: 15px;
      border-radius: 4px;
      margin-bottom: 20px;

      h4 {
        color: #1677ff;
        margin-bottom: 8px;
        // margin-bottom: 15px;
        font-size: 18px;
      }
      .material-category-name-checkbox {
        display: flex;
        gap: 24px;
        align-items: center;
        .select-all-checkbox {
          border: 1px solid #1890ff; /* Blue border for category card */
          border-radius: 5px;
          margin-bottom: 9px;
          padding: 7px;
          // font-size: 18px;
          // color: #1890ff;
        }
      }

      .materials-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .ant-card {
          width: calc(33.333% - 10px);
          &.selected {
            border-color: #1677ff;
          }

          .material-card-content {
            text-align: center;
            .ant-image {
              max-width: 170px;
              max-height: 150px;
              margin-bottom: 10px;
              overflow: hidden;
            }

            p {
              margin-bottom: 0;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
  }
}

.add-associations-modal {
  min-width: 890px;
  .ant-modal {
    &-header {
      margin-bottom: 20px;
    }
    &-title {
      text-align: center;
    }
    &-body {
      height: 600px;
      overflow-y: scroll;
      .bitreel-fullarea-spinner {
        width: 100%;
      }
    }
  }
  .associations-content {
    /* Corrected the class name from 'matterials-content' to 'materials-content' */
    display: flex;
    flex-direction: column; /* Change to column layout to stack part groups vertically */
    gap: 20px; /* Adjust the gap as needed */
    padding-bottom: 15px;

    > div {
      /* Styling for each part group container */
      border: 1px solid #e8e8e8; /* Optional: add border to separate part groups */
      padding: 10px;
      border-radius: 4px;

      h3 {
        /* Styling for part names */
        margin-bottom: 10px;
        color: #1677ff; /* Example: part name color */
        font-size: 16px;
      }

      .associations-card {
        display: inline-flex; /* Display cards inline */
        margin-right: 10px; /* Add some margin to the right of each card */
        margin-bottom: 10px; /* Add some margin below each card */

        &.selected {
          border-color: #1677ff;
        }

        .ant-checkbox-wrapper {
          margin-right: 8px; /* Ensure some space between the checkbox and the image */
        }

        .associations-card-content {
          display: flex;
          align-items: center;
          gap: 10px; /* Adjust the gap between the image and the text */

          img {
            max-width: 50px; /* Adjust the image size as needed */
            max-height: 50px;
            border-radius: 4px;
          }

          p {
            margin-bottom: 0; /* Remove the bottom margin from the paragraph */
          }
        }
      }
    }
  }
}
.view-edit-modal {
  min-width: 1200px;

  .ant-modal {
    &-header {
      margin-bottom: 20px;
    }
    &-title {
      text-align: center;
    }
    &-body {
      height: 1000px;
      overflow-y: scroll;
      padding: 24px;
      .bitreel-fullarea-spinner {
        width: 100%;
      }
    }
  }
  .search-bar {
    /* Styling for each part group container */
    border: 1px solid #e8e8e8; /* Optional: add border to separate part groups */
    padding: 10px;
    border-radius: 4px;
    .search-heading {
      font-size: 16px;

      text-align: left;
    }
    .search-textarea {
      background: #eeeeee80;
      // border: 1px solid var(--Dark, #242424);
    }
    .search-btn {
      display: flex;
      justify-content: center;
      align-items: end;
    }
  }
  .main-content {
    margin-top: 12px;
    .items-selection {
      .items-dropdown {
        border: 1px solid var(--Dark, #242424);

        .panel-header {
          transition: background-color 0.3s; /* Smooth transition for background color */
          color: black !important;

          font-size: 16px;
          font-weight: 600;

          text-align: left;
          .items-name {
            cursor: pointer;
            border-bottom: 1px solid #d9d9d9;
            padding-bottom: 8px;
            color: black;
          }
        }

        .panel-header.active {
          background-color: #4096ff; /* Background color when panel is active */
          color: white !important; /* Optional: Change text color for better contrast */
          border-radius: 7px;
          .items-name {
            cursor: pointer;
            border-bottom: 1px solid #d9d9d9;
            padding-bottom: 8px;
            color: black;
          }
        }
        .rotate-btn {
          padding: 6px;
          border: 1.5px solid var(--Dark, #242424);

          border-radius: 8px;

          .rotate-icon {
            margin-left: -1px;
            color: #242424;
          }
        }
        .rotate-btn-active {
          // background: #1677ff;
          border: 1.5px solid var(--Dark, #e8e8e8);
          background-color: #4096ff;
          border-radius: 8px;
          padding: 6px;
          .rotate-icon-active {
            margin-left: -1px;
            color: #e8e8e8;
          }
        }
      }
    }
    .association-ids-section {
      margin-top: 16px;
      padding: 16px 0px 0px 0px;
      // background-color: red;
      border-radius: 16px;
      border: 1px solid var(--Gray-L, #f0f0f0);

      .association-ids-header {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        .association-header-text {
          display: flex;
          align-items: center;

          font-size: 16px;
          font-weight: 600;

          text-align: left;

          text-align: left;
        }
        .association-btn {
          border: 0.88px solid var(--Gray-L, #f0f0f0);
          padding: 5px;
          border-radius: 7px;
        }
      }
      .ids-section {
        border: 1.5px solid var(--Gray-L, #f0f0f0);
        border-radius: 8px;
        margin: 12px;
        .ids-text {
          align-items: center;
          cursor: pointer;
          border-bottom: 1px solid #d9d9d9;
          // padding-bottom: 8px;
          color: black;
          width: Fill (245px) px;
          font-size: 16px;
          padding: 12px 24px 12px 24px;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
        }
        .ids-text.selected {
          background: #eeeeee;
          border-width: 1px, 0px, 1px, 0px;

          border-color: var(--Gray-L, #f0f0f0);
        }
        .ids-text:hover {
          background: #5b9fff40;
          align-items: center;
          .icons-container {
            gap: 12px;
            display: flex;
            .icon {
              // font-size: 16px;
              // background-color: #1890ff; /* Example color for icons */

              cursor: pointer;
              .edit-icon {
                color: white;
                background: var(--Secondary, #5b9fff);
                padding: 4px;
                border-radius: 4px;
              }

              .delete-icon {
                background: var(--Danger, #ff3c3c);
                padding: 4px;
                color: white;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }
}
.selected-id {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  gap: 16px;
  position: absolute;
  right: 32px;
  top: 32px;
  white-space: nowrap;
  align-items: center;
  z-index: 1;
  
  .selected-id-btn {
    background: #eeeeee40;
    padding: 15px;
    border-radius: 8px;
  }
}
.part-materials-container {
  display: flex;
  flex-direction: column;

  .tabs-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .ant-tabs {
      .ant-tabs-ink-bar {
        background: none;
      }
      .ant-tabs-nav {
        padding: 8px;
        border-radius: 8px;
        border: 1px solid var(--Gray-L, #f0f0f0);
        width: fit-content;
        justify-items: right;
        align-self: flex-end;
        .ant-tabs-nav-wrap {
          .ant-tabs-tab {
            // Default tab styles
            border: 1px solid transparent; // Ensure border doesn't overlap
            transition: background-color 0.3s ease, border-color 0.3s ease; // Smooth transition
            font-size: 17px;
            font-weight: 600;
            width: 190px;
            justify-content: center;
            color: black;
          }

          .ant-tabs-tab-active {
            // Styles for the active tab
            background-color: #1890ff; // Background color for active tab
            color: white; // Text color for active tab

            font-size: 17px;
            font-weight: 600;

            padding: 0px 16px 0px 16px;
            gap: 8px;
            border-radius: 8px;
            .ant-tabs-tab-btn {
              color: white;
            }
          }
        }
      }
    }
  }
}

.part-tab {
  display: flex;
  gap: 24px;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  background: #eeeeee40;
  border: 1px solid var(--Gray-L, #f0f0f0);
  .selected-parts-section {
    padding: 16px;
    width: 450px;
    border-radius: 16px;
    height: 327px;
    overflow-y: scroll;
    border: 1px;
    background: var(--White, #ffffff);
    border: 1px solid var(--Gray-L, #f0f0f0);
    .parts-data {
      border-radius: 8px;
      border: 1px solid var(--Gray-L, #f0f0f0);
      .data-section {
        .data-header {
          font-size: 20px;
          padding: 16px;
          font-weight: 700;
          color: var(--Secondary, #5b9fff);
          border-bottom: 1px solid var(--Gray-L, #f0f0f0);
        }
        .part-items {
          font-size: 14px;
          padding: 16px;
          font-weight: 600;
          // color: var(--Secondary, #5b9fff);
          border-bottom: 1px solid var(--Gray-L, #f0f0f0);
          justify-content: space-between;
          display: flex;
          .discard-btn {
            border-radius: 4.5px;
            cursor: pointer;
            border: 1.5px solid var(--Danger, #ff3c3c);
            .discard-icon {
              color: red;
              padding: 2px;
            }
          }
        }
      }
    }
  }
  .all-parts-section {
    padding: 16px;
    width: 100%;
    border-radius: 16px;
    height: 327px;
    overflow-y: scroll;
    border: 1px;
    background: var(--White, #ffffff);
    border: 1px solid var(--Gray-L, #f0f0f0);
    .all-parts-data {
      border-radius: 8px;
      border: 1px solid var(--Gray-L, #f0f0f0);
      .all-parts-data-section {
        .all-parts-data-header {
          font-size: 20px;
          padding: 16px;
          font-weight: 700;
          color: var(--Secondary, #5b9fff);
          border-bottom: 1px solid var(--Gray-L, #f0f0f0);
        }
        .part-items {
          .parts-selection {
            .parts-selection-rotate-btn {
              padding: 6px;
              border: 1.5px solid var(--Dark, #242424);

              border-radius: 8px;

              .parts-selection-rotate-icon {
                margin-left: -1px;
                color: #242424;
              }
            }
            .parts-selection-rotate-btn-active {
              // background: #1677ff;
              border: 1.5px solid var(--Dark, #e8e8e8);
              background-color: #4096ff;
              border-radius: 8px;
              padding: 6px;
              .parts-selection-rotate-icon-active {
                margin-left: -1px;
                color: #e8e8e8;
              }
            }
            .parts-selection-dropdown {
              // border: 1px solid var(--Dark, #242424);
              border: none;
              .parts-selection-panel-header {
                transition: background-color 0.3s; /* Smooth transition for background color */
                color: black !important;
                font-size: 14px;
                // padding: 16px;
                font-weight: 600;
                border-bottom: 1px solid var(--Gray-L, #f0f0f0);
                // justify-content: space-between;
                // display: flex;

                .parts-selection-items-name {
                  font-size: 14px;
                  padding: 16px;
                  font-weight: 600;
                  border-bottom: 1px solid var(--Gray-L, #f0f0f0);
                  justify-content: space-between;
                  display: flex;
                }
              }

              .parts-selection-panel-header.active {
                background-color: #4096ff; /* Background color when panel is active */
                color: white !important; /* Optional: Change text color for better contrast */
                border-radius: 7px;
                .parts-selection-items-name {
                  font-size: 14px;
                  padding: 16px;
                  font-weight: 600;
                  border-bottom: 1px solid var(--Gray-L, #f0f0f0);
                  justify-content: space-between;
                  display: flex;
                  .add-btn {
                    border-radius: 4.5px;
                    cursor: pointer;
                    border: 1.5px solid var(--Green, #79e946);
                    .add-icon {
                      color: #79e946;
                      padding: 2px;
                    }
                  }
                }
              }
            }
            .parts-selection-panel-hover {
              background: #5b9fff40;
            }
          }
        }
      }
    }
  }
}

.footer-section {
  margin-top: 16px;
  display: flex;
  gap: 32px;
  align-items: flex-start;
  background: var(--White, #ffffff);
  border: 1px solid var(--Gray-L, #f0f0f0);
  border-radius: 16px;
  padding: 32px;

  .footer-section-btn {
    display: flex;
    gap: 8px;
    min-width: 180px;

    .reset-btn, .ant-btn-dangerous {
      font-size: 14px;
      font-weight: 600;
      height: 40px;
      padding: 4px 15px;
      min-width: 80px;
    }
  }

  .message-type-section {
    display: flex;
    flex: 1;
    gap: 32px;

    .ui-message {
      flex: 2;
      .ui-message-header {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
        color: var(--Secondary, #5b9fff);
      }
      .ui-message-input {
        width: 100%;
        background: var(--White, #ffffff);
        border: 1px solid var(--Gray-L, #f0f0f0);
        border-radius: 8px;
        padding: 8px 12px;
        height: 40px;
      }
    }
    .type {
      flex: 1;
      .type-header {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
        color: var(--Secondary, #5b9fff);
      }

      .items-dropdown {
        background: var(--White, #ffffff);
        border: 1px solid var(--Gray-L, #f0f0f0);
        border-radius: 8px;
        
        .ant-collapse-header {
          padding: 8px 12px;
          height: 40px;
        }
      }
      .type-panel-header {
        transition: background-color 0.3s;
        color: black !important;

        .type-items-name {
          cursor: pointer;
          border-bottom: 1px solid #d9d9d9;
          padding-bottom: 8px;
          color: black;
        }
      }

      .type-panel-header.active {
        background-color: #4096ff;
        color: white !important;
        border-radius: 7px;
        .type-items-name {
          cursor: pointer;
          border-bottom: 1px solid #d9d9d9;
          padding-bottom: 8px;
          color: black;
        }
      }
      .rotate-btn {
        padding: 6px;
        border: 1.5px solid var(--Dark, #242424);
        border-radius: 8px;

        .rotate-icon {
          margin-left: -1px;
          color: #242424;
        }
      }
      .type-rotate-btn-active {
        border: 1.5px solid var(--Dark, #e8e8e8);
        background-color: #4096ff;
        border-radius: 8px;
        padding: 6px;
        .type-rotate-icon-active {
          margin-left: -1px;
          color: #e8e8e8;
        }
      }
    }
  }

}
