.ant-image-preview-root {
  .ant-image {
    &-preview-mask {
      border: 3px solid #30a7b8;
    }
    &-preview-body {
      scale: 0.7;
    }
  }
}

.ant-image-preview-operations-wrapper {
  .ant-image {
    &-preview-switch {
      &-right,
      &-left {
        border: 2px solid;
      }
    }
  }
}
