.dimensions-display {
  p {
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.dimensions-input {
  display: flex;
  flex-direction: column;
  label {
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
  input {
    margin-bottom: 10px;
    border: 1px solid grey;
    width: 100px;
  }
}
