.layout-showroom--overview {
  .ant-layout-content {
    padding: 30px 76px;
    background: linear-gradient(360deg, #34d2e4 0%, #1890ff 91.11%);
    .overview-header {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      &--content {
        text-align: center;
        max-width: 500px;
        .ant-typography {
          color: #ffffff;
        }
      }
    }
    .showroom-item-card {
      width: 100%;
      margin-bottom: 20px;
      padding: 20px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      gap: 5px;
      align-content: center;
      align-items: stretch;
      justify-content: space-between;
      height: min-content;
      min-height: 218px;
      box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.25);
      .col {
        &--image {
          .ant-image-img {
            border-radius: 10px;
          }
        }
        &--main {
          flex-grow: 1;
          padding: 10px;
          padding-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
        }
        &--progress {
          display: flex;
          align-items: center;
          padding-left: 20px;
          border-left: 1px solid #dddddd;
          flex-direction: column;
          justify-content: center;
          .progress--title {
            margin-top: 5px;
            font-size: 10px;
          }
        }
      }
    }
  }
}
