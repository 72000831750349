.layout-showroom--merchandising {
  .MODE {
    &__PARAMETER {
      &--hoverProduct {
        cursor: crosshair;
        &--disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
