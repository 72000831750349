.comment-section-container {
  display: flex;
  margin-bottom: 50px;
  .emoty-comment-message {
  }
  .ant-table {
    thead {
      tr {
        th:nth-child(-n + 1) {
          display: none;
        }
      }
    }
    tbody {
      tr {
        &:hover {
          cursor: pointer;
        }
        background: #fff;
        &.selected {
          background: #f5f5f5;
        }
        td:nth-child(-n + 1) {
          display: none;
        }
      }
      .ant-table-expanded-row {
        td {
          display: table-cell !important;
          background: #fff;
        }
        &.selected {
          td {
            background: #f5f5f5;
          }
        }
      }
    }
  }
  .comment-details-container {
    background: linear-gradient(0deg, #f5f5f5, #f5f5f5), #ffffff;
    margin-left: 15px;
    padding: 25px;
    overflow: hidden;
    .comment-details-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 25px;
      p {
        font-family: "Open Sans";
        font-size: 10px;
        font-style: italic;
        font-weight: 300;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .comment-area {
      h3 {
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }
      p {
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
      .view-hotspot-cta {
        p {
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          color: #1890ff;
          cursor: pointer;
          &::before {
            content: " ";
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #1890ff;
            margin-right: 6px;
            display: inline-flex;
            transform: translateY(1px);
          }
        }
      }
    }
    .image-area {
    }
  }
}
