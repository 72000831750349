.file-locker-container {
  background: #eeeeee;
  padding: 40px 185px;
  text-align: center;
  h1 {
    font-family: "Open Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
  }
  h2 {
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 100px;
  }
  .filelocker-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::before {
      width: 100%;
      content: "";
      background-color: #eeeeee;
      height: 2px;
      z-index: 10;
      transform: translateY(1px);
    }
    .file-locker-item {
      padding: 10px 0px;
      width: 40%;
      border-top: 1px solid #bcbcbc;
      border-bottom: 1px solid #bcbcbc;
      display: flex;
      cursor: pointer;
      .locker-item-image {
        width: 125px;
        height: 125px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .locker-item-details {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
  .file-locker-image-preview-container {
    width: 100vw;
    height: 100vw;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.323);
    &__inner {
      width: 80%;
      height: 80%;
      img {
        max-width: 50%;
        max-height: 100%;
      }
    }
  }
}
