.layout-showroom--settings {
  .ant-layout-content {
    padding: 30px 76px;
    .settings-header {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      &--content {
        text-align: center;
        max-width: 500px;
      }
    }
    .settings-footer {
      margin-top: 60px;
      display: flex;
      justify-content: flex-end;
      gap: 15px;
    }
  }
}
