.video-player-preview-list {
  display: flex;
  gap: 10px;
}

.video-player-preview-item {
  position: relative;
  width: 178px;
  height: 178px;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  background-color: #fafafa;

  .anticon {
    font-size: 50px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }

  .title {
    align-self: flex-end;
    font-size: 11px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    color: white;
  }
}
