.portal-button {
  background: linear-gradient(95.23deg, #34d2e4 15.73%, #0091fb 87.97%);
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background: linear-gradient(95.23deg, #2996a2 15.73%, #0077cc 87.97%);
  }
  p {
    color: #fff;
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    padding: 10px 20px;
    margin: 0;
  }
  &.disabled {
    background: linear-gradient(95.23deg, #4f9aa3 15.73%, #6eb1e1 87.97%);
    cursor: not-allowed;
  }
}
