.product-description-display {
  p {
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.product-textarea-input {
  textarea {
    width: 100%;
    min-height: 150px;
  }
}
