.create-variant-advanced-modal {
  width: unset !important;
  transform-origin: unset !important;
  top: 10px !important;
  max-width: 1200px !important;
  .ant-modal-body {
    height: 80vh !important;
    .ant-row {
      height: 100% !important;
      .ant-col {
        height: 100% !important;
        .selectedParts {
          position: absolute;
          top: 5px;
          left: 5px;
          background: #fff;
          z-index: 99999;
          padding: 10px;
          .selected-part {
            &:hover {
              cursor: pointer;
            }
            margin-bottom: 5px;
            .part-name {
              color: red;
              &.completed {
                color: green;
              }
            }
          }
        }
        #bitreel-variant-assembler {
          height: 500px;
          width: 100%;
        }
      }
    }
  }
  .details-container {
    padding: 0px 15px;
    section {
      .section-title {
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
      .editLink {
        margin: 0 4px;
      }
      .bold-text {
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
