.materials-list-screen {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  h2 {
    margin-top: 10px;
  }
  .add-material-button {
    position: absolute;
    top: 75px;
    right: 50px;
  }
  .add-material-category-button {
    position: absolute;
    top: 75px;
    right: 250px;
  }
  .material-category-card {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc; // Example border
    border-radius: 5px; // Example border radius for a card-like appearance
    background-color: #f9f9f9; // Light background color for the card
    h3 {
      margin-bottom: 15px;
    }

    .materials-in-category {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; // This will help distribute the material cards evenly
      .material-card {
        height: 100%;
      }
    }
  }
  .cards-container {
    width: 100%;
    .material-card {
      display: inline-flex;
      width: 30%;
      margin: 20px 1%;
      border: 1px solid black;
      // height: 300px;
      padding: 15px;
      flex-direction: column;
      .top {
        margin-bottom: 15px;
        .top-inner {
          display: flex;
          justify-content: space-between;
          .delete-btn {
            font-size: 30px;
            color: red;
            padding: 2px;
            cursor: pointer;
          }
        }
      }
      .bottom {
        height: 100%;
        flex-grow: 1;
        .material-image {
          height: 200px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        .ant-upload {
          height: 80%;
        }
      }
    }
  }
}
