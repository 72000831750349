.layout-showroom--look-and-feel {
  // height: calc(100vh - 65px);
  .ant-layout-content {
    padding: 20px 25px;
    overflow-y: auto;
    .items-breadcrumb {
      margin-bottom: 15px;
      li {
        cursor: pointer;
        span {
          &:hover {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
    .content-wrapper-card {
      .ant-card-head {
        z-index: 1;
        position: relative;
        .ant-card-head-title {
          i {
            font-weight: normal;
          }
        }
      }

      .ant-card-body {
        height: calc(100vh - 260px);
        overflow-y: auto;
        @extend .scroll-shadows;
        .page-header {
          display: flex;
          justify-content: center;
          margin-bottom: 25px;
          &--content {
            text-align: center;
            max-width: 500px;
          }
        }
      }
      .ant-card-actions {
        .page-footer {
          display: flex;
          justify-content: flex-end;
          gap: 15px;
          padding: 0 15px;
        }
      }
    }
  }
}
