.products-modal.ant-modal {
  width: 95% !important;
  height: 95% !important;
  .ant-modal-content {
    height: 100%;
    padding: 60px 30px;
    .products-categories-container {
      margin-top: 45px;
      .categories-filter {
        display: flex;
        flex-direction: column;
        label {
          margin: 0 0 10px 0;
        }
      }
    }
    .products-list-container {
      display: flex;
      height: calc(100vh - 170px);
      align-content: space-between;
      flex-wrap: wrap;
      .header {
        padding-bottom: 10px;
        width: 100%;
        .search-input {
          max-width: 400px;
          margin-bottom: 15px;
        }
      }
      .content {
        min-height: 300px;
        display: flex;
        flex-wrap: wrap;
        height: calc(100% - 112px);
        overflow-y: auto;
        gap: 20px;
        background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
          linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
          radial-gradient(
            farthest-side at 50% 0,
            rgba(34, 34, 34, 0.5),
            rgba(0, 0, 0, 0)
          ),
          radial-gradient(
              farthest-side at 50% 100%,
              rgba(34, 34, 34, 0.5),
              rgba(0, 0, 0, 0)
            )
            0 100%;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-attachment: local, local, scroll, scroll;
        background-size: 100% 21px, 100% 21px, 100% 7px, 100% 7px;
      }
      .footer {
        text-align: center;
        padding-top: 10px;
        width: 100%;
      }
    }
  }

  .product-item {
    position: relative;
    width: 200px;
    height: 270px;
    .product-thumbnail-container {
      height: 200px;
      border: 1px solid #eee;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:hover {
        background-color: #e6e6e6;
        .add-item-button {
          display: block;
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
      .add-item-button {
        position: absolute;
        display: none;
        background: linear-gradient(95.23deg, #34d2e4 15.73%, #0091fb 87.97%);
        border-radius: 18px;
        border: none;
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;
      }
    }
    .variant-button {
      height: auto;
      position: absolute;
      padding: 5px;
      top: 173px;
      left: 0;
      font-size: 10px;
      line-height: 10px;
      color: #000;
      &:hover {
        > span {
          text-decoration: underline;
        }
      }
    }
    .product-details {
      margin-top: 10px;
      font-size: 10px;
      line-height: 14px;
      p {
        margin: 0;
      }
    }
  }
}
