.media-drop-zone {
  .media-group {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  .files-dropzone {
    min-width: 400px;
  }
}