.no-model-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .ant-empty {
    margin-bottom: 25px;
  }
  h2 {
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.render-area-container {
  height: 100%;
  position: relative;
  .clear-hotspots-container {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
    cursor: pointer;
  }
}
