@import "showroomOverview";
@import "lookAndFeel";
@import "showroomSettings";
@import "showroom3D";
@import "showroomFloorPlan";
@import "merchadising";
@import "showroomFinalReview";
@import "showroomEmpty";
@import "components";

.showroom-layout-wrapper {
  width: 100%;
  // > .engine-focused {
  //   height: calc(100vh - 65px);
  //   overflow: hidden;
  //   .ant-layout-sider-children {
  //     opacity: 0.8;
  //   }
  // }
  .layout-showroom {
    min-height: 100%;
  }
}

.update-model-modal {
  .ant-modal-title {
    font-size: 30px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 23px;
  }
  .content-header {
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 11px;
      font-style: italic;
    }
  }
  .ant-modal-footer {
    .ant-btn {
      border-radius: 18px;
    }
  }
}
