.commenter-form {
  .comment-section {
    &--actions {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
      button {
        font-size: 12px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    &--textarea {
      margin: 5px 0;
    }
    &--title {
      margin: 5px 0;
    }
    &--drop-files {
      margin: 5px 0;
    }
    &--submit-actions {
      margin: 5px 0;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
}
