.dropzone-container {
  background: #fff;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0px;
  p {
    margin: 0;
    padding: 0;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 5px;
    &.subtext {
      font-weight: 300;
      margin-bottom: 0px;
      font-style: italic;
    }
  }
}
