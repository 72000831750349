.ant-layout-content {
  padding: 20px;
  .layout-breadcrumb {
    margin-bottom: 15px;
    li {
      cursor: pointer;
      span {
        &:hover {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
  .content-wrapper-card {
    .ant-card-head-title {
      i {
        font-weight: normal;
      }
    }
  }
  .actions-menu {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .ant-form-item {
    &-label {
      padding-bottom: 5px;
    }
  }
  .commit-buttons {
    display: flex;
    gap: 10px;
  }
}

.ant-layout-content {
  .layout-view {
    // .uploaded-files {
    //   display: flex;
    //   flex-wrap: wrap;
    //   gap: 5px;
    //   a {
    //     width: 180px;
    //     height: 180px;
    //     border: 1px solid red;
    //   }
    // }
  }
  .material-view {
    .ant-image {
      max-height: 300px;
      max-width: 300px;
      min-width: 100px;
      min-height: 100px;
      overflow: hidden;
      border: 1px solid #ccc;
    }
  }
}
