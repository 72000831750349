.commenter-table {
  .ant-table {
    &-row {
      td {
        border-radius: 0 !important;
        font-style: italic;
        color: #545454;
        font-size: 10px;
        height: 60px;
        vertical-align: top;
        padding-top: 8px;
      }
      .ant-table-column-sort {
        background: unset;
      }
      &-selected {
        .ant-table-cell {
          background-color: #f5f5f5 !important;
        }
      }
    }
    &-selection-col {
      display: none;
    }
    &-expand-icon-col {
      display: none;
    }
    &-row-expand-icon-cell {
      display: none;
    }
    &-selection-column {
      display: none;
    }

    &-expanded-row {
      .ant-table-cell {
        border-top: 0 !important;
        padding: 0 !important;

        p.description {
          margin: -60px 0px 0px 0px;
          padding: 34px 8px 26px 8px;
          height: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.ant-table-filter-dropdown {
  .multiselect-filter {
    display: flex;
    flex-direction: column;
    label {
      margin: 5px;
    }
  }
}
