.image-multiple-uploader-modal {
  max-width: 70%;
  .ant-modal-body {
    padding-top: 25px;
    padding-bottom: 15px;
    .images-group {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }
    .files-dropzone {
      min-width: 400px;
    }
  }
}
