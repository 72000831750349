.image-uploader-modal {
  .ant-modal-body {
    padding-top: 25px;
    padding-bottom: 15px;
    // .crop-image {
    //   max-height: calc(100vh - 200px);
    // }
    .files-dropzone {
      min-width: 400px;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      &--crop {
        display: flex;
        gap: 10px;
      }
    }
  }
}
