.layout-showroom--merchandising {
  .siderMenu {
    .ant-collapse {
      // border: 0;
      border-radius: 0;
      &-item {
        margin-bottom: 5px;
        // border-radius: 0;
        // border: 0;
      }
      &-header {
        border-radius: 0 !important;
        background-color: white;
        color: #000000;
      }
    }

    .products-list {
      .add-new-product {
        width: 60px;
        height: 60px;
      }
    }
    .product-placement-editor {
      background-color: #ffffff;
      padding: 10px;
      .product-image {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 15px;
        .ant-image {
          width: 100px;
          height: 100px;
          border: 1px solid #e8e8e8;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
        &-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 1;
          h3 {
            margin-bottom: 3px;
          }
          p {
            margin: 0;
            font-size: 12px;
          }
          .ant-btn {
            margin-top: 5px;
            padding: 0;
          }
        }
      }
      .product-info {
        margin-bottom: 30px;
        p {
          width: 100%;
          margin-bottom: 5px;
          font-size: 12px;
        }
      }
      .product-coordinates {
        h3 {
          font-size: 12px;
        }
        .ant-input-number-group-wrapper {
          margin-bottom: 5px;
        }
      }
      .product-animation {
        h3 {
          font-size: 12px;
        }
      }
    }
    .products-history {
      margin-top: auto;
      .ant-list {
        &-items {
          height: 90px;
          overflow-y: auto;
        }
        &-item {
          display: flex;
          justify-content: start;
          padding-left: 20px;
          padding-right: 20px;
          &.active {
            color: #1677ff;
            background-color: #efefef;
            .anticon-arrow-right {
              margin-left: -17px;
              margin-right: 3px;
            }
          }
          .anticon-info-circle {
            margin-left: auto;
          }
        }
        &-empty-text {
          padding-top: 10px;
          padding-bottom: 10px;
          .ant-empty {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        &-footer {
          display: flex;
          justify-content: end;
          gap: 10px;
        }
      }
    }
  }
}

.history-item-description-popover {
  max-width: 500px;
  .ant-descriptions-item-content {
    .history-item-description-item {
      display: block;
      width: 100%;
      .timeline {
        width: 50px;
        display: inline-block;
      }
    }
  }
}
