.layouts-explorer-context-menu {
  .ant-dropdown-menu-item {
    span {
      i {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}

.add-materials-modal {
  min-width: 890px;
  .ant-modal {
    &-header {
      margin-bottom: 20px;
    }
    &-title {
      text-align: center;
    }
    &-body {
      height: 600px;
      overflow-y: scroll;
      .bitreel-fullarea-spinner {
        width: 100%;
      }
    }
  }
  .materials-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 15px;

    .material-category-card {
      width: 100%;
      border: 1px solid #e8e8e8;
      padding: 15px;
      border-radius: 4px;
      margin-bottom: 20px;

      h4 {
        color: #1677ff;
        margin-bottom: 8px;
        font-size: 18px;
      }
      .material-category-name-checkbox {
        display: flex;
        gap: 24px;
        align-items: center;
        .select-all-checkbox {
          border: 1px solid #1890ff;
          border-radius: 5px;
          margin-bottom: 9px;
          padding: 7px;
        }
      }

      .materials-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .ant-card {
          width: calc(33.333% - 10px);
          &.selected {
            border-color: #1677ff;
          }

          .material-card-content {
            text-align: center;
            .ant-image {
              max-width: 170px;
              max-height: 150px;
              margin-bottom: 10px;
              overflow: hidden;
            }

            p {
              margin-bottom: 0;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
  }
}

.add-associations-modal {
  min-width: 890px;
  .ant-modal {
    &-header {
      margin-bottom: 20px;
    }
    &-title {
      text-align: center;
    }
    &-body {
      height: 600px;
      overflow-y: scroll;
      .bitreel-fullarea-spinner {
        width: 100%;
      }
    }
  }
  .associations-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 15px;

    > div {
      border: 1px solid #e8e8e8;
      padding: 10px;
      border-radius: 4px;

      h3 {
        margin-bottom: 10px;
        color: #1677ff;
        font-size: 16px;
      }

      .associations-card {
        display: inline-flex;
        margin-right: 10px;
        margin-bottom: 10px;

        &.selected {
          border-color: #1677ff;
        }

        .ant-checkbox-wrapper {
          margin-right: 8px;
        }

        .associations-card-content {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            max-width: 50px;
            max-height: 50px;
            border-radius: 4px;
          }

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.view-edit-modal {
  min-width: 1064px;

  .ant-modal {
    &-header {
      margin-bottom: 20px;
    }
    &-title {
      text-align: center;
    }
    &-body {
      height: 600px;
      overflow-y: scroll;
      .bitreel-fullarea-spinner {
        width: 100%;
      }
    }
  }
  .search-bar {
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-radius: 4px;
    .search-heading {
      font-size: 16px;
      text-align: left;
    }
    .search-textarea {
      background: #eeeeee80;
    }
    .search-btn {
      display: flex;
      justify-content: center;
      align-items: end;
    }
  }
  .main-content {
    margin-top: 12px;
    .items-selection {
      .items-dropdown {
        border: 1px solid var(--Dark, #242424);

        .panel-header {
          transition: background-color 0.3s;
          color: black !important;
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          .items-name {
            cursor: pointer;
            border-bottom: 1px solid #d9d9d9;
            padding-bottom: 8px;
            color: black;
          }
        }

        .panel-header.active {
          background-color: #4096ff;
          color: white !important;
          border-radius: 7px;
          .items-name {
            cursor: pointer;
            border-bottom: 1px solid #d9d9d9;
            padding-bottom: 8px;
            color: black;
          }
        }
        .rotate-btn {
          padding: 6px;
          border: 1.5px solid var(--Dark, #242424);
          border-radius: 8px;

          .rotate-icon {
            margin-left: -1px;
            color: #242424;
          }
        }
        .rotate-btn-active {
          border: 1.5px solid var(--Dark, #e8e8e8);
          background-color: #4096ff;
          border-radius: 8px;
          padding: 6px;
          .rotate-icon-active {
            margin-left: -1px;
            color: #e8e8e8;
          }
        }
      }
    }
    .association-ids-section {
      margin-top: 16px;
      padding: 16px 0px 0px 0px;
      border-radius: 16px;
      border: 1px solid var(--Gray-L, #f0f0f0);

      .association-ids-header {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        .association-header-text {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          text-align: left;
        }
        .association-btn {
          border: 0.88px solid var(--Gray-L, #f0f0f0);
          padding: 5px;
          border-radius: 7px;
        }
      }
      .ids-section {
        border: 1.5px solid var(--Gray-L, #f0f0f0);
        border-radius: 8px;
        margin: 12px;
        .ids-text {
          align-items: center;
          cursor: pointer;
          border-bottom: 1px solid #d9d9d9;
          color: black;
          width: Fill (245px) px;
          font-size: 16px;
          padding: 12px 24px 12px 24px;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
        }
        .ids-text.selected {
          background: #eeeeee;
          border-width: 1px, 0px, 1px, 0px;
          border-color: var(--Gray-L, #f0f0f0);
        }
        .ids-text:hover {
          background: #5b9fff40;
          align-items: center;
          .icons-container {
            gap: 12px;
            display: flex;
            .icon {
              cursor: pointer;
              .edit-icon {
                color: white;
                background: var(--Secondary, #5b9fff);
                padding: 4px;
                border-radius: 4px;
              }

              .delete-icon {
                background: var(--Danger, #ff3c3c);
                padding: 4px;
                color: white;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }
}

.selected-id {
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  text-align: left;
  gap: 16px;
  position: absolute;
  white-space: nowrap;
  align-items: center;
  .selected-id-btn {
    background: #eeeeee40;
    padding: 15px;
    border-radius: 8px;
  }
}

.part-materials-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tabs-container {
    width: 100%;

    .ant-tabs {
      .ant-tabs-ink-bar {
        background: none;
      }
      .ant-tabs-nav {
        padding: 8px;
        border-radius: 8px;
        border: 1px solid var(--Gray-L, #f0f0f0);
        width: fit-content;
        justify-items: right;
        align-self: flex-end;
        .ant-tabs-nav-wrap {
          .ant-tabs-tab {
            border: 1px solid transparent;
            transition: background-color 0.3s ease, border-color 0.3s ease;
            font-size: 17px;
            font-weight: 600;
            width: 190px;
            justify-content: center;
            color: black;
          }

          .ant-tabs-tab-active {
            background-color: #1890ff;
            color: white;
            font-size: 17px;
            font-weight: 600;
            padding: 0px 16px 0px 16px;
            gap: 8px;
            border-radius: 8px;
            .ant-tabs-tab-btn {
              color: white;
            }
          }
        }
      }
    }
  }
}

.footer-section {
  .message-type-section {
    display: flex;
    gap: 14px;
    margin-top: 12px;
    .ui-message {
      flex: 1;
      .ui-message-header {
        font-size: 20px;
        font-weight: 700;
        color: var(--Secondary, #5b9fff);
      }
      .ui-message-input {
        padding: 12px;
      }
    }
    .type {
      flex: 1;
      .type-header {
        font-size: 20px;
        font-weight: 700;
        color: var(--Secondary, #5b9fff);
      }
      .type-panel-header {
        transition: background-color 0.3s;
        color: black !important;

        .type-items-name {
          cursor: pointer;
          border-bottom: 1px solid #d9d9d9;
          padding-bottom: 8px;
          color: black;
        }
      }

      .type-panel-header.active {
        background-color: #4096ff;
        color: white !important;
        border-radius: 7px;
        .type-items-name {
          cursor: pointer;
          border-bottom: 1px solid #d9d9d9;
          padding-bottom: 8px;
          color: black;
        }
      }
      .rotate-btn {
        padding: 6px;
        border: 1.5px solid var(--Dark, #242424);
        border-radius: 8px;

        .rotate-icon {
          margin-left: -1px;
          color: #242424;
        }
      }
      .type-rotate-btn-active {
        border: 1.5px solid var(--Dark, #e8e8e8);
        background-color: #4096ff;
        border-radius: 8px;
        padding: 6px;
        .type-rotate-icon-active {
          margin-left: -1px;
          color: #e8e8e8;
        }
      }
    }
  }
  .footer-section-btn {
    margin-top: 12px;
    display: flex;
    gap: 12px;
    .save-btn {
      font-size: 16px;
      font-weight: 600;
    }
    .reset-btn {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

/* Material-specific styles */
.material-tab {
  display: flex;
  gap: 24px;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  background: #eeeeee40;
  border: 1px solid var(--Gray-L, #f0f0f0);

  .selected-materials-section {
    padding: 16px;
    width: 450px;
    border-radius: 16px;
    height: 327px;
    overflow-y: scroll;
    border: 1px;
    background: var(--White, #ffffff);
    border: 1px solid var(--Gray-L, #f0f0f0);

    .materials-data {
      border-radius: 8px;
      border: 1px solid var(--Gray-L, #f0f0f0);

      .data-section {
        .data-header {
          font-size: 20px;
          padding: 16px;
          font-weight: 700;
          color: var(--Secondary, #5b9fff);
          border-bottom: 1px solid var(--Gray-L, #f0f0f0);
        }

        .material-items {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          padding: 16px;
          font-weight: 600;
          gap: 8px;

          .child-item-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            background: #fafafa;
            border-radius: 4px;
            width: 100%;

            .child-item {
              flex: 1;
            }

            .discard-btn {
              border-radius: 4.5px;
              cursor: pointer;
              border: 1.5px solid var(--Danger, #ff3c3c);
              .discard-icon {
                color: red;
                padding: 2px;
              }
            }
          }
        }
      }
    }
  }

  .all-materials-section {
    padding: 16px;
    width: 100%;
    border-radius: 16px;
    height: 327px;
    overflow-y: scroll;
    border: 1px;
    background: var(--White, #ffffff);
    border: 1px solid var(--Gray-L, #f0f0f0);

    .all-materials-data {
      border-radius: 8px;
      border: 1px solid var(--Gray-L, #f0f0f0);

      .all-materials-data-section {
        .all-materials-data-header {
          font-size: 20px;
          padding: 16px;
          font-weight: 700;
          color: var(--Secondary, #5b9fff);
          border-bottom: 1px solid var(--Gray-L, #f0f0f0);
        }

        .material-items {
          .materials-selection {
            .materials-selection-rotate-btn {
              padding: 6px;
              border: 1.5px solid var(--Dark, #242424);
              border-radius: 8px;

              .materials-selection-rotate-icon {
                margin-left: -1px;
                color: #242424;
              }
            }

            .materials-selection-rotate-btn-active {
              border: 1.5px solid var(--Dark, #e8e8e8);
              background-color: #4096ff;
              border-radius: 8px;
              padding: 6px;

              .materials-selection-rotate-icon-active {
                margin-left: -1px;
                color: #e8e8e8;
              }
            }

            .materials-selection-dropdown {
              border: none;

              .materials-selection-panel-header {
                transition: background-color 0.3s;
                color: black !important;
                font-size: 14px;
                font-weight: 600;
                border-bottom: 1px solid var(--Gray-L, #f0f0f0);

                .materials-selection-items-name {
                  font-size: 14px;
                  padding: 16px;
                  font-weight: 600;
                  border-bottom: 1px solid var(--Gray-L, #f0f0f0);
                  justify-content: space-between;
                  display: flex;
                }
              }

              .materials-selection-panel-header.active {
                background-color: #4096ff;
                color: white !important;
                border-radius: 7px;

                .materials-selection-items-name {
                  font-size: 14px;
                  padding: 16px;
                  font-weight: 600;
                  border-bottom: 1px solid var(--Gray-L, #f0f0f0);
                  justify-content: space-between;
                  display: flex;

                  .add-btn {
                    border-radius: 4.5px;
                    cursor: pointer;
                    border: 1.5px solid var(--Green, #79e946);
                    .add-icon {
                      color: #79e946;
                      padding: 2px;
                    }
                  }
                }
              }
            }

            .materials-selection-panel-hover {
              background: #5b9fff40;
            }
          }
        }
      }
    }
  }
}
