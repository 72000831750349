.ant-layout-sider {
  background-color: #fff;
  .explorer {
    height: calc(100vh - 76px);
    // height: 100%;
    padding: 20px 10px;
    .title {
      text-align: center;

      // text-underline-offset: 3px;
      margin-bottom: 15px;
      span {
        padding: 0 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.85);
        // text-decoration: underline;
      }
    }
    .collection {
      max-width: 100%;
      padding: 2px 5px;
      height: 27px;
      margin-bottom: 5px;
      &.active {
        background-color: #e6f4ff;
        &:hover {
          background-color: #e6f4ff;
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
    .ant-spin-nested-loading {
      .ant-spin {
        & > span {
          inset-inline-start: 83%;
        }
      }
    }
    .ant-tree {
      height: calc(100vh - 222px);
      overflow-y: auto;
      padding-right: 5px;
      // height: calc(100% - 77px);
      .explorer-collection-item {
        .ant-tree-switcher {
          display: none;
        }
      }
    }
    .footer {
      position: sticky;
      bottom: 0px;
      padding-bottom: 10px;
      background-color: #fff;
      text-align: center;
      z-index: 1;
      .ant-divider {
        margin: 10px 0;
        box-shadow: 0px -3px 8px -1px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px -3px 8px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px -3px 8px -1px rgba(0, 0, 0, 0.75);
      }
    }
  }
}
