.advanced-product-creator-wrapper {
  @import "layoutSider";
  @import "layoutContent";
  .action-loading-indicator {
    position: fixed;
    right: 24px;
    bottom: 24px;
    > .anticon {
      font-size: 50px;
    }
  }
}
@import "partAssociationModals";
@import "materialAssociationModals";
