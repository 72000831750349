.circle-menu {
  &_product {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    z-index: -1;
    opacity: 0;

    .menu {
      box-sizing: content-box;
      background-color: rgba(252, 252, 252, 0.15);
      opacity: 0.8;
      // backdrop-filter: blur(2px);
      height: 100px;
      width: 100px;
      transform: scale(0);
      border-radius: 50%;
      border: 1px solid #fff;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      transition: scale 0.7s;
      &:hover {
        background-color: rgba(255, 255, 255, 0.4);
        opacity: 1;
      }
    }

    .menu-item {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      font-size: 15px;
      color: #4e4e4e;
      &:hover {
        color: #30a7b8;
      }
    }

    .menu-item:nth-child(1) {
      top: 6px;
      left: 45px;
    }

    .menu-item:nth-child(2) {
      top: 24px;
      left: 77px;
    }

    .menu-item:nth-child(3) {
      top: 58px;
      left: 76px;
    }

    .menu-item:nth-child(4) {
      top: 78px;
      left: 42px;
    }

    .menu-item:nth-child(5) {
      top: 58px;
      left: 10px;
    }

    .menu-item:nth-child(6) {
      top: 23px;
      left: 12px;
    }

    &.expanded {
      opacity: 1;
      transition: opacity 0.6s linear;

      z-index: 1;
      .menu {
        transform: scale(3);
      }
      .fa-plus {
        transform: rotate(45deg);
      }
    }
  }

  .dismiss {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 30px;
    padding: 5px;
    margin: 10px;
    border: 2px solid #ff4646;
    z-index: -1;
    border-radius: 5px;
    color: #ff4646;
    opacity: 0;
    cursor: pointer;
    background-color: rgba(252, 252, 252, 0.7);
  }

  &.hidden-mode {
    .circle-menu_product {
      display: none;
    }
    .dismiss {
      z-index: 3;
      opacity: 0.8;
      &:hover {
        background-color: rgba(252, 252, 252, 0.7);
        opacity: 1;
      }
    }
  }
}
