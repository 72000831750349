.commenter-viewer {
  h3 {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
  }
  .info-row {
    ul {
      padding: 0;
      li {
        display: inline;
        padding: 0 10px;
        border-right: 1px solid #000000;
        font-style: italic;
        font-size: 12px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          border-right: none;
        }
      }
    }
  }
  .comment-row {
    p {
      font-family: "Open Sans";
      font-size: 12px;
      line-height: 16px;
    }
  }
  .annotations-row {
    .ant-btn {
      padding: 0;
      display: flex;
      align-items: center;
      .annotation-icon {
        font-size: 44px;
        line-height: 15px;
        margin-right: 3px;
      }
    }
  }
  .images-row {
    margin-top: 35px;
    // h3 {
    //   display: block;
    //   width: 100%;
    //   font-weight: bold;
    // }
    .images-container {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .thumbnail-wrapper {
        position: relative;
        .remove-btn {
          position: absolute;
          top: 3px;
          z-index: 2;
          right: 4px;
          font-size: 10px;
          width: 18px;
          min-width: 18px;
          height: 18px;
          &:hover {
            color: white;
            border-color: white;
            background-color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
}
