.bitreel-item-collection-table {
  .bitreel-expanded-itemcollection-row {
    .item-collection-actions {
      padding-bottom: 16px;
    }
    .item-card {
      section {
        margin-bottom: 20px;
        .section-title {
          border-bottom: 1px solid black;
          display: inline-block;
          text-align: center;
          margin-bottom: 15px;
        }
        .card-field {
          label {
            font-weight: 500 !important;
          }
          padding-bottom: 10px;
        }
        .card-field-header {
          label {
            font-weight: 500 !important;
          }
          span {
            font-weight: 400;
          }
          padding-bottom: 10px;
        }
      }
    }
  }
  .ant-table-column-sort {
    background: #fff;
  }
  .manage {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    &:hover {
      cursor: pointer;
      background-color: cornflowerblue;
    }
  }
}
