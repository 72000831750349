.siderMenu--rooms {
  background-color: white !important;
  .explorer {
    height: 100%;
    padding: 10px;
    .explorer-tree {
      position: relative;
      height: 100%;
      .title {
        text-align: center;
        padding-top: 14px;
        // text-underline-offset: 3px;
        margin-bottom: 17px;
        & > span {
          padding: 0 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.85);
          // text-decoration: underline;
          .anticon {
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
      .ant-tree {
        &-treenode {
          width: 100%;
          &-selected {
            .item-wrapper {
              .ant-btn {
                display: block !important;
              }
            }
          }
        }
        &-node-content-wrapper {
          display: flex;
          width: 100%;
        }
        &-title {
          width: 100%;
          .item-wrapper {
            display: flex;
            flex-wrap: wrap;
            b {
              margin-right: 5px;
            }
            .ant-btn {
              margin-left: auto;
              display: none;
            }
          }
        }
      }
      .environment-item {
        .ant-tree {
          &-title {
            margin-left: 3px;
          }
          &-switcher {
            display: none;
          }
          &-node-content-wrapper {
            padding-left: 0;
          }
        }
      }
      .add-room-item {
        .ant-tree {
          &-node-content-wrapper {
            width: auto;
          }
        }
      }
      // .footer {
      //   position: absolute;
      //   bottom: 0px;
      //   text-align: center;
      //   z-index: 1;
      //   width: 100%;
      //   .ant-divider {
      //     margin: 10px 0;
      //     box-shadow: 0px -3px 8px -1px rgba(0, 0, 0, 0.75);
      //     -webkit-box-shadow: 0px -3px 8px -1px rgba(0, 0, 0, 0.75);
      //     -moz-box-shadow: 0px -3px 8px -1px rgba(0, 0, 0, 0.75);
      //   }
      // }
    }
  }
}
