@import "form";
@import "table";
@import "viewer";

.commenter-drawer-button {
  position: absolute;
  bottom: 0px;
  right: 0;
  height: auto;
  background: transparent !important;
  padding: 5px 10px;
  .anticon {
    font-size: 30px;
    color: #fff !important;
  }
  .ant-badge-count {
    background-color: #30a7b8;
  }
}
.commenter-section {
  &--header {
    text-align: center;
  }
  .commenter-table,
  .commenter-viewer {
    .status {
      &-created {
        color: #1890ff;
      }
      &-in_progress {
        color: #ff0000;
      }
      &-in_review {
        color: #ff8b26;
      }
      &-resolved {
        color: #43a416;
      }
    }
  }
}
