.create-layouts {
  width: unset !important;
  transform-origin: unset !important;
  top: 10px !important;
  .ant-modal-body {
    height: calc(100vh - 80px) !important;
    .modal-content-container {
      overflow: scroll;
      height: 100%;
    }
    .layout-list {
      height: 90% !important;
      .innerlist-container {
        height: 100% !important;
        overflow: auto !important;
        overflow-x: hidden !important;
      }
    }
  }
  // .ant-modal-footer {
  //     display: none !important;
  // }
  .layout-details {
    height: 100%;
    display: flex;
    flex-direction: column;
    .layout-image {
      flex-grow: 1;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.material-cards-container {
  overflow: scroll;
  max-height: 400px;
  .material-card {
    display: inline-flex;
    width: 30%;
    margin: 20px 1%;
    border: 1px solid black;
    padding: 15px;
    flex-direction: column;
    height: 220px;
    .top {
      margin-bottom: 15px;
    }
    .bottom {
      flex-grow: 1;
      .material-image {
        height: 100%;
        background-size: contain;
        background-position: center;
      }
      .ant-upload {
        height: 80%;
      }
    }
  }
}
