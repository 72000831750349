.comment-viewer {
  margin-top: 25px;
  h3 {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
    span {
      font-family: Open Sans;
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 4px;
    }
  }
  .comment-title,
  .refs-title {
    margin-bottom: 10px;
  }
  .button-area {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 10px;
    // justify-content: space-between;
    .button {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
      svg {
        margin-right: 5px;
      }
      label {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 123.2%;
        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.108);
        }
      }
      &:hover {
        cursor: pointer;
        label {
          background: rgba(0, 0, 0, 0.108);
        }
        // background: rgba(0, 0, 0, 0.108);
      }
    }
  }
  textarea {
    width: 100%;
    min-height: 200px;
    resize: none;
    margin-bottom: 25px;
  }
  .submit-container {
    display: flex;
    justify-content: flex-end;
  }
}
