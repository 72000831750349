.image-uploader {
  display: flex;
  align-items: center;
  .ant-upload-wrapper {
    width: auto;
    .ant-upload-list-item-actions {
      height: 100%;
      .anticon-eye {
        height: 100% !important;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-upload-list-item-action {
        display: none;
      }
    }
  }
  .file-properties {
    .file-name {
      font-style: italic;
      font-size: 12px;
    }
    .file-actions {
      .ant-btn {
        font-size: 12px;
        padding: 0;
        margin: 0;
      }
      .divider {
        margin: 0 5px;
      }
    }
  }
}
