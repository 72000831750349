.layout-showroom--empty {
  .ant-layout-content {
    padding: 30px 76px;
    .showroom-header {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      &--content {
        text-align: center;
        max-width: 500px;
      }
    }
  }
}
