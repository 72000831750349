.version-select-section {
  border-bottom: 1px solid #bcbcbc;
  h2 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 15px;
  }
  p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    span {
      font-weight: 400;
    }
    margin-bottom: 7px;
  }
  .ant-select {
    margin-bottom: 7px;
  }
  .bottom-options {
    display: flex;
    margin-bottom: 15px;
    a {
    }
    .spacer {
      width: 1px;
      background-color: black;
      margin: 0px 6px;
      transform: scale(1, 0.5);
    }
  }
}
