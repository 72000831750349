.layout-showroom-3d {
  .scene-wrapper {
    // height: 800px !important; // Only for debug
    position: relative;
    canvas {
      outline: none !important;
      display: flex;
    }

    // &:hover {
    //   .scene-layover {
    //     display: flex;
    //   }
    // }

    // .scene-layover {
    //   display: none;
    //   position: absolute;
    //   align-items: center;
    //   justify-content: center;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: rgb(0, 0, 0);
    //   background: radial-gradient(
    //     circle,
    //     rgba(0, 0, 0, 0.3) 0%,
    //     rgba(148, 187, 233, 0) 100%
    //   );
    //   cursor: pointer;
    //   z-index: 10;
    //   &--title {
    //     user-select: none;
    //     color: white;
    //     font-size: 20px;
    //     display: flex;
    //     align-items: center;
    //     gap: 5px;
    //   }
    // }

    // &.is-focused {
    //   .scene-layover {
    //     display: none;
    //   }
    // }
    // &.not-focused {
    //   canvas {
    //     pointer-events: none;
    //   }
    // }

    .controls-view-mode {
      position: absolute;
      top: 20px;
      left: calc(50% - 81px);
      .ant-segmented {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 16px;
        &-thumb {
          background: linear-gradient(112.56deg, #34d2e4 14.67%, #0091fb 85.17%),
            #0091fb;
          border-radius: 15px;
        }
        &-item {
          border-radius: 15px;
          background: transparent;
          &-label {
            font-weight: bold;
            line-height: 44px;
            font-size: 11px;
            color: white;
          }
          &-selected {
            background: linear-gradient(
                112.56deg,
                #34d2e4 14.67%,
                #0091fb 85.17%
              ),
              #0091fb;
          }
        }
      }

      // .ant-segmented-item {
      //   &-label {
      //     font-weight: bold;
      //     line-height: 44px;
      //   }
      //   background: transparent;
      //   &-selected {
      //     background: linear-gradient(112.56deg, #34d2e4 14.67%, #0091fb 85.17%),
      //       #0091fb;
      //   }
      // }
    }
    .controls-view-tools {
      position: absolute;
      left: 5px;
      top: 5px;
      display: flex;
      flex-direction: column;
      .ant-btn {
        border-radius: 0;
        width: 45px;
        height: 40px;
      }
      .controls-view-tools-button {
        &.selected {
          color: #0091fb;
          border-color: #0091fb;
        }
      }
    }
    .controls-view-action {
      position: absolute;
      top: calc(50% - 60px);
      right: 0px;
      display: flex;
      flex-direction: column;
      .ant-btn {
        border-radius: 0;
        width: 45px;
        height: 40px;
      }
    }
  }
  .ant-layout-sider-children {
    background-color: #f5f5f5;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .page-first-section {
    height: calc(100vh - 65px);
    &--left {
      overflow-y: auto;
    }
    .bitreel-fullarea-spinner {
      width: 100%;
      height: 100%;
    }
    .babylon-col {
      background-color: #ffffff;
    }
  }
  .page-second-section {
    border-top: 1px solid #eeeeee;
  }
  .siderMenu {
    height: 100%;
    .ant-card {
      background-color: #f5f5f5;
    }
  }
}
