.precision-input-number {
  width: 100%;
  .ant-input-number + .ant-input-number-group-addon {
    padding: 0;
    .ant-btn {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
